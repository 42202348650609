import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

interface SEOProps {
  title: string
  description: string | null
  type: string
  image: string
}

const SEO: FunctionComponent<SEOProps> = (props) => {
  let description = (props.description ?? '').replace(/(<([^>]+)>)/gi, '')
  description = description.replace(/&#\d\d\d\d;/g, '')
  description = description.substring(0, 500)
  if (description.length === 500) {
    description = `${description} ...`
  }
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteURL
        }
      }
    }
  `)
  return (
    <Helmet title={props.title}>
      <meta name="description" content={description} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content="@john___green" />
      <meta property="og:type" content={props.type} />
      <meta property="og:description" content={description} />
      <meta property="og:title" content={props.title} />
      <meta
        property="og:image"
        content={`${site.siteMetadata.siteURL}${props.image}`}
      />
      <meta property="og:image:width" content="450" />
      <meta property="og:image:height" content="450" />
      <link
        rel="apple-touch-icon"
        href="/motorhome-180x180.png"
        type="image/png"
      />
      <link rel="icon" href="/motorhome.svg" type="image/svg+html" />
      <link rel="manifest" href="/manifest.json" />
      <html lang="en" />
    </Helmet>
  )
}

export default SEO
