import React, {
  useState,
  useContext,
  useEffect,
  forwardRef,
  LegacyRef,
  ForwardedRef,
} from 'react'
import { Link } from 'gatsby'
import { GlobalContext, NavType, ContextType } from './GlobalContextProvider'
import * as navStyles from './Nav.module.css'

export const updateNav = (skipPrevious = false) => {
  const { nav, setNav } = useContext(GlobalContext) as ContextType
  let newPreviousPage = nav.previousPage
  const { currentPage } = nav
  if (!skipPrevious) {
    newPreviousPage = currentPage
  }
  useEffect(() => {
    if (window === undefined) {
      return
    }
    setNav({
      previousPage: newPreviousPage,
      currentPage: window.location.pathname,
    })
  }, [])
}

const Nav = forwardRef<HTMLButtonElement | HTMLAnchorElement>((props, ref) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { nav, years } = useContext(GlobalContext) as ContextType
  const menuList = [
    { link: '/', text: 'Home' },
    { link: '/map', text: 'Map' },
    ...years.map((entry) => ({
      link: `/map/${entry.title}`,
      text: `Map (${entry.title})`,
    })),
    { link: '/blogPage', text: 'Blog' },
  ]

  return (
    <nav className={navStyles.navContainer}>
      {nav.previousPage !== null && (
        <div>
          <Link
            to={nav.previousPage}
            className={`${navStyles.back} ${navStyles.navButton}`}
            aria-label="go back to previous page"
            ref={ref}
          >
            <svg
              width="34"
              height="34"
              version="1.1"
              viewBox="0 0 8.9958 8.9958"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(0 -288)">
                <path
                  className="arrow"
                  d="m3.0677 295.25-2.7521-2.7522 2.7521-2.7521v1.479h5.6125v2.5462h-5.6125z"
                  fill="#000"
                />
              </g>
              <desc>back arrow</desc>
            </svg>
          </Link>
          <button
            className={`${navStyles.hamburger} ${navStyles.navButton}`}
            aria-label="toggle menu"
            onClick={() => setMenuOpen(!menuOpen)}
          />
        </div>
      )}
      {nav.previousPage === null && (
        <button
          className={`${navStyles.hamburger} ${navStyles.navButton}`}
          aria-label="toggle menu"
          ref={ref as LegacyRef<HTMLButtonElement>}
          onClick={() => setMenuOpen(!menuOpen)}
        />
      )}
      <div
        className={`${navStyles.menu}${menuOpen ? ` ${navStyles.open}` : ''}`}
      >
        {menuList.map((entry) => (
          <Link key={entry.link} className={navStyles.menuItem} to={entry.link}>
            {entry.text}
          </Link>
        ))}
      </div>
    </nav>
  )
})

export default Nav
